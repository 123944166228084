.home-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gmaps-header {
  background-color: #994500;
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 24px;
}

.gmaps-header-text {
  margin-right: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #f3f4f6;
}

.home-gmap-dropdown {
  width: 25%;
}

.weather-station-data-div,
.spray-distance-calculator-div {
  box-shadow: 0 5px 10px rgb(136, 134, 134);
  border: 1px solid #bfc1c7;
  border-radius: 10px;
  overflow: hidden;
}

// media screens

@media screen and (max-width: 479.5px) {
  .gmaps-header {
    display: block;
  }

  .gmaps-header-text {
    margin-bottom: 10px;
  }

  .home-gmap-dropdown {
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .gmaps-header {
    display: block;
  }

  .gmaps-header-text {
    margin-bottom: 10px;
  }

  .home-gmap-dropdown {
    width: 60%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .gmaps-header {
    display: block;
  }

  .gmaps-header-text {
    margin-bottom: 10px;
  }

  .home-gmap-dropdown {
    width: 60%;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .gmaps-header-text {
    margin-bottom: 10px;
    max-width: 60%;
  }

  .home-gmap-dropdown {
    width: 60%;
  }
}

@root-entry-name: default;