.body-text {
  margin: 12px 0;
  padding: 0 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.add-user-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.drawer-form-footer {
  margin-top: auto;
}

.add-user-drawer-form-footer-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 32px;
  padding: 26px 25px 16px 0;
  background-color: #f3f4f6;
}

.add-user-drawer-form-footer-buttons button {
  background: none;
  outline: none;
  border: none;
}

button.cancel-button {
  color: #35363a;
}

button.add-user-button {
  color: #fff;
  background-color: #994500;
  border-radius: 24px;
  padding: 10px 16px;
}

@root-entry-name: default;