@import url(/src/global.less);

.App {
  min-height: 100%;
  width: 100%;
}

.disable-button,
.disable-custom-inp {
  opacity: 0.5;
  cursor: not-allowed;
}

@root-entry-name: default;