.legals-layout-div {
  height: 100vh;
}

.legals-layout-content-div {
  min-height: 100vh;
  margin: 2rem 8rem;
}

.legals-back-to-app-div {
  font-size: 14px;
  font-weight: 600;
  margin: 2rem 8rem;
}

.legals-back-to-home {
  text-decoration: none;
}

@root-entry-name: default;