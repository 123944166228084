.settings-custom-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  overflow-y: hidden;
}

.settings-custom-modal-content {
  width: 1070px;
  height: 510px;
  background: #fff;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  margin-top: 100px;
}

.settings-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.settings-custom-modal-body {
  display: grid;
  grid-template-columns: 25% 75%;
  height: 100%;
}

.settings-side-nav {
  width: 100%;
  background-color: #f3f4f6;
  text-align: center;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.settings-delete-account-prompt-text {
  margin-bottom: 30px;
}

.settings-delete-account-button {
  background: none;
  outline: none;
  border: none;
  color: #cf3537;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.settings-tabs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.settings-tab {
  border: none;
  outline: none;
  background: none;
  color: rgba(153, 69, 0, 0.7);
  padding: 5px 10px;
}

.settings-active {
  color: #994500;
  font-weight: 600;
  line-height: 20px;
  border-bottom: 2px solid #994500;
}

.settings-custom-body {
  padding: 20px 30px;
}

.settings-custom-body-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.security-ruler {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #c2c7d0;
  width: 100%;
}

.settings-custom-body-title-text {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.settings-custom-body-subtitle-text {
  color: #696f88;
  font-size: 12px;
}

.settings-custom-body-tab-content {
  margin-top: 15px;
}

/* mobile screens */

@media screen and (max-width: 479.5px) {
  .settings-custom-modal-content {
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
  }

  .settings-custom-modal-body {
    display: block;
    height: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .settings-custom-modal-content {
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
  }

  .settings-custom-modal-body {
    display: block;
    height: 100%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .settings-custom-modal-content {
    width: 90%;
    max-height: 80%;
  }

  .settings-custom-modal-body {
    display: grid;
    grid-template-columns: 35% 65%;
    height: 100%;
  }

  .settings-side-nav,
  .settings-custom-body {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .settings-custom-body-tab-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (min-width: 768px) and (max-width: 979.5px) {
  .settings-custom-modal-content {
    width: 90%;
    max-height: 80%;
  }

  .settings-custom-modal-body {
    display: grid;
    grid-template-columns: 30% 70%;
    height: 100%;
  }

  .settings-side-nav,
  .settings-custom-body {
    padding: 20px 30px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (min-width: 980px) and (max-width: 1080px) {
  .settings-custom-modal-content {
    width: 90%;
    max-height: 80%;
  }

  .settings-custom-modal-body {
    display: grid;
    grid-template-columns: 25% 75%;
    height: 100%;
  }

  .settings-side-nav,
  .settings-custom-body {
    padding: 20px 30px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@root-entry-name: default;