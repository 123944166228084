.weather-station-container {
  padding: 20px;
}

.home-weather-station-buttons-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-weather-station-link-div {
  margin-right: 10px;
}

.home-weather-station-link-div .spray-weather-link {
  color: #994500;
  font-weight: 600;
  text-decoration: none;
}

.button {
  background: none;
  border: 1px solid #994500;
  outline: none;
  color: #696f88;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

.button.p-button {
  border-radius: 10px 0px 0px 10px;
  padding: 12px 15px;
}

.button.a-button {
  border-radius: 0 10px 10px 0;
  padding: 12px 29px;
}

.button.activeBtn {
  background-color: #994500;
  color: #f3f4f6;
}

/* TABLE CSS */
.weather-station-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.75%;
  margin-bottom: 8px;
}

// new css

.weather-station-table-home {
  width: 100%;
  text-align: center;
}

.column-home {
  background: rgba(230, 161, 69, 0.5);
  color: #14151c;
  padding: 20px 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.tableHeader1-home td,
.tableData1-home td {
  border: 1px solid #c2c7d0;
}

.tableHeader3-home .column-home {
  border: none;
}

.data-column-home {
  border: none;
}

.stripped-home {
  background-color: #f3f4f6;
}

.th-home {
  background: #994500;
  color: #f3f4f6;
  padding: 20px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.tbl-row-border-home {
  border-bottom: 1px solid #c2c7d0;
}

.data-column-home {
  padding: 10px 5px;
}

.weather-station-table-home,
.tableHeader3-home {
  border: 1px solid #c2c7d0;
  border-collapse: collapse;
}

/* Legends and arrow css */
.weather-legends-container {
  margin: 10px 0;
  border-bottom: 1px solid #c2c7d0;
}

.weather-main-legend {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.weather-legends {
  display: grid;
  grid-template-columns: 50% 50%;
}

.weather-legend {
  margin: 12px 0;
}

.weather-legend-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.weather-legend-row.five-legends {
  grid-template-columns: repeat(3, 1fr);
}

.weather-legend-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.weather-legend-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.weather-legend-item .weather-item-label {
  margin: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.home-recommendation {
  margin: 40px 0;
}

.recommendation-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 5px 0;
}

// media screens

@media screen and (max-width: 479.5px) {
  .home-weather-station-buttons-section {
    display: block;
    margin-bottom: 1rem;
  }

  .button {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .main-table-div {
    overflow-x: auto;
  }

  .column-home {
    min-width: 200px;
  }

  .weather-legends {
    display: block;
  }

  .weather-legend-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .weather-legend-row.five-legends {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .home-weather-station-buttons-section {
    display: block;
    margin-bottom: 1rem;
  }

  .button {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .main-table-div {
    overflow-x: auto;
  }

  .column-home {
    min-width: 180px;
  }

  .weather-legends {
    display: block;
  }

  .weather-legend-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .weather-legend-row.five-legends {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .main-table-div {
    overflow-x: auto;
  }

  .weather-legends {
    display: block;
  }

  .weather-legend-row {
    grid-template-columns: repeat(3, 1fr);
  }

  .weather-legend-row.five-legends {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .weather-legends {
    display: block;
  }
}

@root-entry-name: default;