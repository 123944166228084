.myprofile-pi {
  border: 1px solid #c2c7d0;
  border-radius: 10px;
  height: auto;
  min-height: 180px;
  padding: 10px 20px;
}
.pi-header {
  display: flex;
  justify-content: space-between;
}
.pi-edit-button {
  background-color: none;
  border: none;
  border-radius: 24px;
  background-color: #994500;
  color: white;
  padding: 10px 16px;
}
.pi-cancel-button {
  margin-right: 15px;
  border: none;
  background-color: none;
  background-color: white;
  padding: 10px 16px;
}
.pi-savechanges-button {
  border: none;
  background-color: none;
  background-color: #994500;
  border-radius: 20px;
  color: white;
  padding: 10px 16px;
}
.profile-information {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.pi-name-heading {
  color: #696f88;
}
.pi-name {
  font-weight: bold;
}
.profile-email-address {
  margin-top: 20px;
}
.pi-emailaddress {
  font-weight: bold;
}

.myprofile-address {
  border: 1px solid #c2c7d0;
  border-radius: 10px;
  height: auto;
  min-height: 120px;
  padding: 10px 20px;
  margin-top: 16px;
}
.myprofile-address-edit-button {
  display: flex;
  justify-content: space-between;
}

.profile-adress-information {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

// mobile screens

@media screen and (max-width: 479.5px) {
  .pi-header {
    display: block;
  }

  .pi-edit-button,
  .pi-savechanges-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .pi-buttons-div,
  .myprofile-address-buttons-div {
    margin: 0.5rem 0;
  }

  .profile-information,
  .profile-adress-information {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
  }

  .profile-information .d-block,
  .profile-adress-information .d-block {
    margin: 0.5rem 0;
  }

  .myprofile-address-edit-button {
    display: block;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .pi-header {
    display: block;
  }

  .pi-edit-button,
  .pi-savechanges-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .pi-buttons-div,
  .myprofile-address-buttons-div {
    margin: 0.5rem 0;
  }

  .profile-information,
  .profile-adress-information {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
  }

  .profile-information .d-block,
  .profile-adress-information .d-block {
    margin: 0.5rem 0;
  }

  .myprofile-address-edit-button {
    display: block;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .pi-header {
    display: block;
  }

  .pi-edit-button,
  .pi-savechanges-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .pi-buttons-div,
  .myprofile-address-buttons-div {
    margin: 0.5rem 0;
  }

  .profile-information,
  .profile-adress-information {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
  }

  .profile-information .d-block,
  .profile-adress-information .d-block {
    margin: 0.5rem 0;
  }

  .myprofile-address-edit-button {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 979.5px) {
  .pi-edit-button,
  .pi-savechanges-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .pi-buttons-div,
  .myprofile-address-buttons-div {
    margin: 0.5rem 0;
  }

  .profile-information,
  .profile-adress-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .profile-information .d-block,
  .profile-adress-information .d-block {
    margin: 0.5rem 0;
  }
}

@root-entry-name: default;