@mb: 35px;

.recoverPassword-container-send-email {
  margin-top: @mb;
}

.provide-email-text {
  margin-bottom: 30px;
  color: #4d5165;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 130px;
}

.send-button {
  height: 40px;
  color: #fff;
  background: #994500;
  border: none;
  border-radius: 24px;
  padding: 0 22px;
}

// ---------------------------------------------------------------
.recoverPassword-container-send-email-success {
  margin-top: @mb;
}

.content-2 {
  margin-bottom: 25px;
}

.didnt-receive-text {
  color: #707374;
  margin-bottom: 10px;
}

.resend-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 24px;
  background: #994500;
  color: #fff;
  border: none;
  padding: 10px 28px;
}

.error-message {
  margin-top: 5px;
  color: #cf3537;
  text-align: left;
  font-size: 12px;
}

/* mobile screens */

@media screen and (max-width: 479.5px) {
  .recoverPassword-container-send-email,
  .recoverPassword-container-send-email-success {
    margin-top: 20px;
  }
}

@root-entry-name: default;