.admin-dashboard-div {
  -webkit-transform: translateY(-74px);
          transform: translateY(-74px);
}

.count-row {
  gap: 40px;
  margin-bottom: 15px;
}

.count-row .col {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.col {
  background-color: white;
  border: 1px solid #a3a9b9;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.heading {
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #a3a9b9;
}

.heading span {
  padding-left: 30px;
}

.middle {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: last baseline;
}

.middle .count-value {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  color: #363948;
}

.growth {
  color: #46db4d;
  font-size: 14px;
}

.loss {
  color: red;
}

/* ........charts.......... */

.chart-col {
  border-radius: 10px;
  /* border: 1px solid #dfe2e7; */
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.chart-div {
  border: 1px solid #a3a9b9;
  height: 550px;
  min-height: 550px;
  max-height: 550px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
}

.barheight {
  height: 480px;
  min-height: 480px;
  max-height: 480px;
}

.headingborder {
  border-bottom: 1px solid #a3a9b9;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.year {
  padding-left: 5%;
  font-weight: normal;
}

.dropdownbox {
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #a3a9b9;
  font-size: 14px;
}

.dashboard-line-chart-btn {
  background: none;
  outline: none;
  border: 1px solid #dfe2e7;
  padding: 2px 16px;
  font-weight: 500;
  line-height: 28px;
  color: #a3a9b9;
}

.dashboard-line-chart-activeButton {
  color: #994500;
}

// media screens

@media screen and (max-width: 479.5px) {
  .count-row {
    gap: 6px;
    margin-bottom: 15px;
  }

  .heading {
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #a3a9b9;
    display: flex;
    justify-content: space-between;
  }

  .middle {
    padding-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: last baseline;
    grid-gap: 5px;
    gap: 5px;
  }

  .middle .count-value {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #363948;
  }

  .count-percentage-difference {
    text-align: end;
    font-size: 14px;
  }

  .chart-div {
    border: 1px solid #a3a9b9;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
  }

  .barheight {
    height: 100%;
    min-height: 400px;
    max-height: 400px;
  }

  .chart-heading {
    font-size: 14px;
  }

  .dashboard-chart-row {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .count-row {
    gap: 6px;
    margin-bottom: 15px;
  }

  .heading {
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #a3a9b9;
    display: flex;
    justify-content: space-between;
  }

  .middle {
    padding-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: last baseline;
    grid-gap: 5px;
    gap: 5px;
  }

  .middle .count-value {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #363948;
  }

  .count-percentage-difference {
    text-align: end;
    font-size: 14px;
  }

  .chart-div {
    border: 1px solid #a3a9b9;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
  }

  .barheight {
    height: 100%;
    min-height: 400px;
    max-height: 400px;
  }

  .chart-heading {
    font-size: 14px;
  }

  .dashboard-chart-row {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .count-row {
    gap: 6px;
    margin-bottom: 15px;
  }

  .heading {
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #a3a9b9;
    display: flex;
    justify-content: space-between;
  }

  .middle {
    padding-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: last baseline;
    grid-gap: 5px;
    gap: 5px;
  }

  .middle .count-value {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #363948;
  }

  .count-percentage-difference {
    text-align: end;
    font-size: 14px;
  }

  .chart-div {
    border: 1px solid #a3a9b9;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .chart-heading {
    font-size: 14px;
  }

  .dashboard-chart-row {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .count-row {
    gap: 6px;
    margin-bottom: 15px;
  }

  .heading {
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #a3a9b9;
    display: flex;
    justify-content: space-between;
  }

  .middle {
    padding-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: last baseline;
    grid-gap: 5px;
    gap: 5px;
  }

  .middle .count-value {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #363948;
  }

  .count-percentage-difference {
    text-align: end;
    font-size: 14px;
  }
}

@root-entry-name: default;