.infestation-maps-cards {
  display: flex;
  justify-content: space-around;
  margin: 2rem;
}

.infestation-maps-card {
  text-align: center;
  padding: 10px 20px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.infestation-maps-card-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.infestation-maps-card-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.infestation-maps-graph-image-div {
  width: 100%;
}

.infestation-maps-graph-image {
  max-width: 450px;
  object-fit: contain;
}

.infestation-maps-legend {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  gap: 10px;
  margin: 0 20px;
}

.infestation-maps-legend-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 1rem;
}

.infestation-maps-legend-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.infestation-maps-legend-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.infestation-maps-legend-item .infestation-maps-item-label {
  margin: 8px;
  font-size: 12px;
  text-align: left;
}

.infestation-maps-legend-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  justify-content: space-between;
}

.infestation-maps-legend-item {
  display: flex;
  gap: 10px;
}

// media screens

@media screen and (max-width: 479.5px) {
  .infestation-maps-cards {
    display: block;
    margin: 1rem;
  }

  .infestation-maps-card {
    width: 100%;
    padding: 20px;
    margin-bottom: 1rem;
  }

  .infestation-maps-graph-image {
    width: 100%;
    max-width: 450px;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .infestation-maps-cards {
    display: block;
    margin: 1rem auto;
  }

  .infestation-maps-card {
    width: 100%;
    max-width: 450px;
    padding: 20px;
    margin: 1rem auto 1.5rem;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .infestation-maps-cards {
    display: block;
    margin: 1rem auto;
  }

  .infestation-maps-card {
    width: 100%;
    max-width: 450px;
    padding: 20px;
    margin: 1rem auto 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .infestation-maps-cards {
    display: block;
    margin: 1rem;
  }

  .infestation-maps-card {
    width: 100%;
    max-width: 450px;
    padding: 20px;
    margin: 1rem auto 1.5rem;
  }
}

@root-entry-name: default;