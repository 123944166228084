@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@400;600&display=swap");

.quill-text-editor.full-height {
  height: 99%;
}

.editor-container {
  border-radius: 4px;
  border: 1px solid #c2c7d0;
  background: #fff;
  height: 100%;
  overflow-y: auto;
}

.ql-container {
  height: calc(100% - 42px);
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre {
  font-family: "Noto Sans Display";
  color: #000;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.105px;
}

@root-entry-name: default;