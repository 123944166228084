.container-fluid {
  margin: 0;
  padding: 0;
}

.home-page-content {
  padding-top: 76px;
  margin-bottom: 24px;
}

.avatar-initials {
  height: 40px;
  width: 40px;
  border: 1px solid #dfe2e7;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  color: #4d5165;
}

@root-entry-name: default;