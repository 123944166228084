.custom-redirect-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.custom-redirect-body {
  width: 400px;
  background: #fff;
  border-radius: 16px;
  padding: 30px;
  position: relative;
  z-index: 999;
  text-align: center;
}

.loader-text {
  font-size: 16px;
  font-weight: 600;
}

@root-entry-name: default;