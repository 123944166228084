.login-button {
  height: 40px;
  color: #fff;
  background: #994500;
  border: none;
  border-radius: 24px;
}

.form-control {
  color: #696f88;
  background-color: #f3f4f6;
}

.form-label {
  color: #14151c;
  margin-bottom: 2px;
}

.text-end {
  color: #0071cd;
  text-align: right;
  cursor: pointer;
}

.no-decoration {
  text-decoration: none;
}

.error-message {
  font-size: 14px;
  color: #cf3537;
}

@root-entry-name: default;