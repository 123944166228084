.layout-footer {
  background-color: #f7f7f7;
}

.footer-copyright-div {
  padding: 20px;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
}

.footer-divider {
  height: 1px;
  background: #c2c7d0;
}

.layout-footer-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  padding: 30px 20px;
  background-color: #212121;
}

.footer-link {
  font-size: 14px;
  margin: 12px 0;
  text-decoration: none;
  color: #fff;
}

.footer-link:hover {
  color: #e98330;
}

.nav-underline .nav-link.active {
  border-bottom: 4px solid #000;
}

@media screen and (max-width: 479.5px) {
  .layout-footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .layout-footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .layout-footer-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@root-entry-name: default;