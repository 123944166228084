.epidemic-pressure-development-content-div {
  padding: 20px 25px;
}

.epidemic-pressure-weatherstation {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.epidemic-pressure-weatherstation-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #2f3031;
}

.epidemic-pressure-weatherstation-date {
  color: #696f88;
  font-size: 16px;
}

.epidemic-pressure-cards {
  display: flex;
  justify-content: space-around;
  margin: 30px 20px;
}

.epidemic-pressure-card {
  width: 530px;
  height: 384px;
  padding: 10px 20px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.epidemic-pressure-card-title {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #2f3031;
}

.epidemic-pressure-graph-image {
  width: 500px;
  height: 330px;
  object-fit: contain;
}

.epidemic-pressure-loading-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.epidemic-pressure-legend {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  gap: 10px;
  margin: 0 20px;
}

.epidemic-pressure-legend-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.epidemic-pressure-legend-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 50%;
}

.epidemic-pressure-legend-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.epidemic-pressure-legend-item .epidemic-pressure-item-label {
  margin: 8px;
  font-size: 12px;
}

// media screens

@media screen and (max-width: 479.5px) {
  .epidemic-pressure-weatherstation {
    justify-content: space-between;
  }

  .epidemic-pressure-cards {
    display: block;
    margin: 0.5rem 0;
  }

  .epidemic-pressure-card {
    width: 90vw;
    max-width: 530px;
    margin-bottom: 1.5rem;
  }

  .epidemic-pressure-graph-image {
    width: 100%;
    object-fit: contain;
  }

  .epidemic-pressure-legend-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .epidemic-pressure-cards {
    display: block;
    margin: 0.5rem auto;
  }

  .epidemic-pressure-card {
    width: 90vw;
    max-width: 530px;
    margin-bottom: 1.5rem;
  }

  .epidemic-pressure-graph-image {
    width: 100%;
    object-fit: contain;
  }

  .epidemic-pressure-legend-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .epidemic-pressure-cards {
    display: block;
    margin: 1rem auto;
  }

  .epidemic-pressure-card {
    width: 90%;
    max-width: 530px;
    margin: 0.5rem auto 1.5rem;
  }

  .epidemic-pressure-graph-image {
    width: 100%;
    object-fit: contain;
  }

  .epidemic-pressure-legend-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .epidemic-pressure-cards {
    display: block;
    margin: 1rem auto;
  }

  .epidemic-pressure-card {
    width: 90%;
    max-width: 500px;
    margin: 0.5rem auto 1.5rem;
  }

  .epidemic-pressure-graph-image {
    width: 100%;
    object-fit: contain;
  }

  .epidemic-pressure-legend-row {
    width: 100%;
  }
}

@media screen and (min-width: 960.1px) and (max-width: 1080px) {
  .epidemic-pressure-cards {
    display: block;
    margin: 1rem auto;
  }

  .epidemic-pressure-card {
    width: 90%;
    max-width: 500px;
    margin: 0.5rem auto 1.5rem;
  }

  .epidemic-pressure-graph-image {
    width: 100%;
    object-fit: contain;
  }

  .epidemic-pressure-legend-row {
    width: 100%;
  }
}

@root-entry-name: default;