.security {
  border: 1px solid #c2c7d0;
  height: auto;
  min-height: 200px;
  border-radius: 10px;
  padding: 10px 20px;
}

.security-password {
  /* display: flex;
  justify-content: space-between; */
}

.security-password-row {
  display: flex;
  justify-content: space-between;
}

.security-changepassword-button {
  background-color: none;
  border: none;
  background-color: #994500;
  color: white;
  border-radius: 20px;
  padding: 10px 16px;
}

.click-security-changepassword-button {
  display: flex;
  margin: 10px;
}

.security-cancel-button {
  margin-right: 15px;
  border: none;
  background-color: none;
  background-color: white;
  padding: 10px 16px;
}

.security-save-changes-button {
  border: none;
  background-color: none;
  background-color: #994500;
  border-radius: 20px;
  color: white;
  padding: 10px 16px;
}

.click-password-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  gap: 5px;
}

.form-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1c1414;
  margin-top: 10px;
}

// mobile screens

@media screen and (max-width: 479.5px) {
  .security-changepassword-button,
  .security-save-changes-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .security-password-row {
    display: block;
  }

  .security-changepassword-buttons-div {
    margin: 0.5rem 0;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .security-changepassword-button,
  .security-save-changes-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .security-password-row {
    display: block;
  }

  .security-changepassword-buttons-div {
    margin: 0.5rem 0;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .security-changepassword-button,
  .security-save-changes-button {
    border-radius: 16px;
    padding: 8px 12px;
  }

  .security-password-row {
    display: block;
  }

  .security-changepassword-buttons-div {
    margin: 0.5rem 0;
  }
}

@root-entry-name: default;