.users-container {
  background-color: #fff;
  height: 660px;
  -webkit-transform: translateY(-74px);
          transform: translateY(-74px);
  border: 1px solid #dfe2e7;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px 19px;
}

.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headers-left {
  width: 40%;
}

.no-default-form-control {
  background-color: #fff;
}

.headers-right {
  display: flex;
  gap: 15px;
}

.headers-right button {
  padding: 10px 16px;
  border-radius: 24px;
}

.export-to-excel-btn {
  color: #994500;
  border: 1px solid #994500;
  background-color: #fff;
}

.add-user-btn {
  color: #fff;
  border: 1px solid #994500;
  background-color: #994500;
}

.users-content {
  margin: 15px 0;
  height: 90%;
}

.user-table {
  background: #d9d9d9;
  border: 1px solid #c2c7d0;
  border-radius: 10px;
  height: auto;
  max-height: 100%;
  overflow-y: scroll;
}

.user-table table tr th,
.user-table table tr td {
  padding: 13px 10px !important;
}

.loader-div {
  text-align: center;
}

.users-name {
  color: #0071cd;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
}

.rotate180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.users-status {
  border-radius: 4px;
  padding: 0px 8px;
  font-weight: 600;
  font-size: 12px;
}

.users-status.active {
  color: #0c612c;
  background: #c3ead1;
  border: 1px solid #c3ead1;
}

.users-status.inactive {
  color: #9c2628;
  background: #ffd9d9;
  border: 1px solid #ffd9d9;
}

// media screens

@media screen and (max-width: 479.5px) {
  .users-container {
    font-size: 14px;
  }

  .users-header {
    display: flex;
    flex-direction: column;
  }

  .headers-left {
    width: 100%;
    margin-bottom: 1rem;
  }

  .no-default-form-control {
    background-color: #fff;
  }

  .headers-right {
    display: flex;
    gap: 15px;
  }

  .headers-right button {
    padding: 8px 10px;
    border-radius: 18px;
  }

  .table-header,
  .table-data {
    text-wrap: nowrap;
  }

  .user-table {
    height: 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .users-container {
    font-size: 14px;
  }

  .users-header {
    display: flex;
    flex-direction: column;
  }

  .headers-left {
    width: 100%;
    margin-bottom: 1rem;
  }

  .no-default-form-control {
    background-color: #fff;
  }

  .headers-right {
    display: flex;
    gap: 15px;
  }

  .headers-right button {
    padding: 8px 10px;
    border-radius: 18px;
  }

  .table-header,
  .table-data {
    text-wrap: nowrap;
  }

  .user-table {
    height: 90%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .users-container {
    font-size: 14px;
  }

  .users-header {
    display: flex;
    flex-direction: column;
  }

  .headers-left {
    width: 100%;
    margin-bottom: 1rem;
  }

  .no-default-form-control {
    background-color: #fff;
  }

  .headers-right {
    display: flex;
    gap: 15px;
  }

  .headers-right button {
    padding: 8px 10px;
    border-radius: 18px;
  }

  .table-header,
  .table-data {
    text-wrap: nowrap;
  }

  .user-table {
    height: 90%;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .users-container {
    font-size: 14px;
  }

  .users-header {
    display: flex;
    justify-content: space-between;
  }

  .headers-left {
    width: 100%;
    margin-bottom: 1rem;
  }

  .no-default-form-control {
    background-color: #fff;
  }

  .headers-right {
    display: flex;
    gap: 15px;
  }

  .headers-right button {
    padding: 8px 10px;
    border-radius: 18px;
  }

  .table-header,
  .table-data {
    text-wrap: nowrap;
  }

  .user-table {
    height: 90%;
  }
}

@root-entry-name: default;