.gmaps-map {
  background-color: #fdfdfd;
  width: auto;
  color: black;
  box-shadow: 0 5px 10px rgb(96, 94, 94);
  border: 1px solid #bfc1c7;
  border-radius: 10px;
}

.map-container-style {
  width: 100%;
  /* height: 80vh; */
  height: 578px;
  min-height: 400px;
}

.gmaps-dot-container {
  height: auto;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 1px;
}

.gmaps-marker {
  height: 20px;
  width: 20px;
  border: 1px solid #a3a9b9;
  border-radius: 50%;
}

/* legend */
.map-legends-container {
  margin: 10px 0;
}

.map-main-legend {
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 5px;
  margin-left: 24px;
}

.map-legends {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 24px;
  gap: 24px;
}

.map-legend-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-left: 2%;
}

.map-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.map-legend-item .item-label {
  margin-left: 3%;
  margin-right: 6%;
}

// media screens

@media screen and (max-width: 479.5px) {
  .map-legend-row {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 2%;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .map-legend-row {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 2%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .map-legend-row {
    grid-template-columns: repeat(3, 1fr);
    margin-left: 2%;
  }
}

@root-entry-name: default;