.error-boundary-page {
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: 10%;
  text-align: center;
  height: 75vh;
  font-size: 18px;
  font-weight: 700;
}

.error-boundary-oops {
  color: #000;
  font-size: 24px;
}

.error-boundary-message {
  color: #ff0000;
}

.error-boundary-click-here {
  text-decoration: underline;
  color: #0d6efb;
  cursor: pointer;
}

@root-entry-name: default;