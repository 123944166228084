@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Display";
  letter-spacing: -0.0075em;
}

// scrollbar css
::-webkit-scrollbar {
  width: 5px;
  margin-left: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfe2e7;
  border-radius: 8px;
  background-clip: content-box;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3a9b9;
  border-radius: 8px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

// custom password tooltip
.custom-password-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-password-tooltip .custom-password-tooltiptext,
.custom-password-tooltip .custom-other-tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: #fff;
  color: red;
  border: 1px solid #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 9;
  top: -18px;
  left: 150%;

  opacity: 0;
  transition: opacity 1s;
}

.custom-password-tooltip .custom-password-tooltiptext {
  width: 320px;
}

.custom-password-tooltip .custom-other-tooltiptext {
  min-width: 120px;
  max-width: 200px;
}

.custom-password-tooltip .custom-password-tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
}

.custom-password-tooltip:hover .custom-password-tooltiptext {
  visibility: visible;
  opacity: 1;
}

.required-field {
  color: #ff0000;
}

.required-pflichtfeld-text {
  text-align: right;
  font-size: 12px;
  font-weight: 600;
}

.initial-loader {
  text-align: center;
  padding-top: 10%;
  height: 100vh;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.password-eye-button {
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: center;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.cna {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}

.pointer {
  cursor: pointer;
}
