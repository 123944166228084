.register-button {
  height: 40px;
  color: #fff;
  background: #994500;
  border: none;
  border-radius: 24px;
}

.form-check-inline {
  margin-right: 2rem;
}

.form-control {
  color: #696f88;
  background-color: #f3f4f6;
}

.form-label {
  color: #14151c;
  margin-bottom: 2px;
}

.privacy-label {
  font-size: 12px;
  text-decoration-line: underline;
  color: #005693;
  cursor: pointer;
}

.no-pl > .col-md-6:nth-child(2) {
  padding-left: 0px;
}

.no-pl > .col-md-3 {
  padding-right: 0px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.my-0 {
  margin: 0;
}

@media screen and (max-width: 479.5px) {
  .no-pl > .col-md-6:nth-child(2) {
    padding-left: 12px;
  }

  .no-pl > .col-md-3:first-child {
    padding-right: 12px;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .no-pl > .col-md-6:nth-child(2) {
    padding-left: 12px;
  }

  .no-pl > .col-md-3:first-child {
    padding-right: 12px;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .no-pl > .col-md-6:nth-child(2) {
    padding-left: 12px;
  }

  .no-pl > .col-md-3:first-child {
    padding-right: 12px;
  }
}

@root-entry-name: default;