.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.email-template-container {
  background: #efefef;
}

.email-template-container-inside {
  background: #fff;
  padding-top: 5px;
}

.email-template-header {
  margin: 10px 15px;
}

.email-template-header-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #14151c;
}

hr.email-template-ruler {
  background: #c2c7d0;
  border: 1px solid #c2c7d0;
  width: 100%;
  margin-top: 0;
}

.email-template-footer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-template-message-div {
  padding: 1rem;
}

.email-template-buttons-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin: 15px 8px;
}

.email-template-buttons-div button {
  padding: 10px 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.email-template-cancel-btn,
.email-template-send-test-mail-btn {
  color: #994500;
  border: 1px solid #994500;
  background-color: #fff;
}

.email-template-save-btn,
.email-template-preview-btn {
  color: #fff;
  border: 1px solid #994500;
  background-color: #994500;
}

// email template editor
.main-email-template {
}

.email-template-text-editor {
  border-radius: 10px;
  border: 1px solid #c2c7d0;
  background: #f3f4f6;
  margin-bottom: 15px;
}

.email-template-preview-container {
}

.email-template-preview {
  border-radius: 10px;
  border: 1px solid #c2c7d0;
  background: #fff;
  margin-bottom: 15px;
}

.editor-container-title {
  border-radius: 10px 10px 0px 0px;
  background: #994500;
  color: #f3f4f6;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 7px 15px;
}

.editor-container-body {
  padding: 15px 20px;
}

.editor-container-body.second-container-body {
  height: 750px;
  overflow-y: auto;
}

.email-container-body {
  padding: 15px 10px;
  height: 1354px;
  overflow-y: auto;
}

.header-salutation-container {
}

.body-container {
}

.form-control-style {
  color: #a3a9b9;
}

// custom tabs

.custom-nav-link {
  color: #696f88;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.09px;
}

.nav-tabs {
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-border-width: 0;
}

li.nav-item {
  color: #696f88;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.09px;
  border: 1px solid #994500;
  background: #fff;
}

.nav-tabs .nav-link {
  margin-bottom: 0;
  color: #696f88;
}

.nav-tabs .nav-link.active {
  color: #fff;
  background: #994500;
}

.nav-item:first-child {
  border-radius: 4px 0px 0px 4px;
}

.nav-item:last-child {
  border-radius: 0px 4px 4px 0px;
}

// tabContent

.weather-stations-div {
  color: #14151c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: none;
}

// media screens

@media screen and (max-width: 479.5px) {
  .email-template-buttons-div {
    display: block;
  }

  .email-template-footer-div {
    display: block;
  }

  .email-template-send-test-mail-btn {
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .email-template-buttons-div {
    display: block;
  }

  .email-template-footer-div {
    display: block;
  }

  .email-template-send-test-mail-btn {
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .email-template-buttons-div {
    display: block;
  }

  .email-template-footer-div {
    display: block;
  }

  .email-template-send-test-mail-btn {
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .email-template-footer-div {
    display: block;
  }

  .email-template-buttons-div button {
    padding: 6px 10px;
    border-radius: 18px;
  }
}

@root-entry-name: default;