.spray-distance-calculator {
}

.spray-heading-div {
  background-color: #994500;
  padding: 16px 25px;
}

.spray-heading {
  color: #f3f4f6;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
}

.spray-subheading {
  color: #f3f4f6;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@root-entry-name: default;