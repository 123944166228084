.dashboard-container {
  padding: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}

.dashboard-body {
}

.tab-container {
  background-color: #994500;
  height: 170px;
}

.dashboard-tab {
  padding: 12px 62px 0;
}

.dashboard-tab button {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 0 5px;
  margin: 0 17px;
  transition: 0.3s all ease-in-out;
  text-align: center;
}

/* Create an active/current tablink class */
.dashboard-tab button.active {
  color: #fff;
  border-bottom: 3.5px solid #fff;
}

.dashboard-tab-content {
  height: 100%;
  padding: 0 62px;
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
  background-color: #f3f4f6;
  position: relative;
}

// media screens

@media screen and (max-width: 479.5px) {
  .dashboard-tab {
    padding: 12px 8px 0;
  }

  .dashboard-tab-content {
    padding: 0 8px;
    min-height: 50%;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .dashboard-tab {
    padding: 12px 24px 0;
  }

  .dashboard-tab-content {
    padding: 0 24px;
    min-height: 50%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .dashboard-tab {
    padding: 12px 24px 0;
  }

  .dashboard-tab-content {
    padding: 0 24px;
    min-height: 50%;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .dashboard-tab {
    padding: 12px 24px 0;
  }

  .dashboard-tab-content {
    padding: 0 24px;
    min-height: 50%;
  }
}

@root-entry-name: default;