.profile-div {
  margin-bottom: 50px;
  padding: 0 24px;
}

.profile-details {
  display: flex;
  align-items: center;
  gap: 14px;
}

.profile-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #14151c;
}

.profile-activity {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #696f88;
}

.activity-time {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #14151c;
}

.drawer-form-body {
  padding-left: 24px;
  padding-right: 24px;
}

.update-user-drawer-form-footer-buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 32px;
  padding: 26px 25px 16px 0;
  background-color: #f3f4f6;
}

.update-user-drawer-form-footer-buttons button {
  background: none;
  outline: none;
  border: none;
}

button.cancel-button {
  color: #35363a;
}

button.update-user-button {
  color: #fff;
  background-color: #994500;
  border-radius: 24px;
  padding: 10px 16px;
}

.drawer-form-footer {
  margin-top: auto;
}

.delete-account {
  padding: 0 24px;
}

.delete-account-text {
  color: #696f88;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.divider {
  display: block;
  margin: 0.5rem auto !important;
  border: 1px solid #a3a9b9;
}

.delete-account-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 22px;
}

.delete-account-button {
  background: none;
  outline: none;
  border: none;
  color: #cf3537;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.account-deleted-message {
  padding: 20px 0;
  text-align: center;
  color: #cf3537;
}
.email-verified .form-check{
  display: flex;
  flex-direction: row-reverse;
  justify-content:flex-end;
  padding-left: 0; 
  margin-top: 10px;
  margin-bottom: -5px;
}
.email-verified .form-check .form-check-input{
  margin-left: 10px;
}
.email-verified .form-check .form-check-label{
  font-size: 14px;
  font-weight: 400;
}

@root-entry-name: default;