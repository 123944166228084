.terms-and-conditions-container .terms-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.header-text {
  font-weight: 600;
  white-space: nowrap;
}

.terms-ruler {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #868ca2;
  width: 100%;
}

.terms-content {
  text-align: left;
  height: 280px;
  max-height: 280px;
  overflow-y: auto;
}

.terms-content-text {
  color: #707374;
  width: 98%;
}

.terms-footer {
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  margin: 32px 0 4px;
}

.terms-footer button {
  background: none;
  outline: none;
  border: none;
}

button.accept-button {
  color: #fff;
  background-color: #994500;
  border-radius: 24px;
  padding: 10px 16px;
}

.close-button {
  color: #14151c;
}

// scrollbar css
::-webkit-scrollbar {
  width: 5px;
  margin-left: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfe2e7;
  border-radius: 8px;
  background-clip: content-box;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3a9b9;
  border-radius: 8px;
}

@root-entry-name: default;