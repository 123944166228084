.offcanvas {
  width: 30% !important;
}

.offcanvas.offcanvas-end {
  width: 30% !important;
}

.offcanvas-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #14151c;
  padding: 0 8px;
}

.offcanvas-body {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  box-sizing: border-box;
}

.form-control {
  color: #696f88;
  background-color: #f3f4f6;
}

.form-label {
  color: #14151c;
  margin-bottom: 2px;
}

.drawer-container {
  padding: 0;
  height: calc(100% - 60px);
}

// media screens

@media screen and (max-width: 479.5px) {
  .offcanvas,
  .offcanvas.offcanvas-end {
    width: 90% !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
  .offcanvas,
  .offcanvas.offcanvas-end {
    width: 75% !important;
  }
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
  .offcanvas,
  .offcanvas.offcanvas-end {
    width: 60% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .offcanvas,
  .offcanvas.offcanvas-end {
    width: 50% !important;
  }
}

@root-entry-name: default;