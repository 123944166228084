@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@400;600&display=swap");

.email-template {
  /* margin: 2% 10%;
   padding: 1% 0; 
   border: 1px solid #a3a9b9; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

/* ul {
  margin: 30px 0;
}

ul > li {
  list-style: none;
} */

a {
  color: #0071cd;
}

/* email-header */
.email-header {
}

.date {
  text-align: end;
  margin: 10px;
}

.banner {
  width: 100%;
}

.banner-img {
  width: 100%;
  margin: 10px 0;
}

/* email-body */
.email-body {
  margin: 0 15px;
}

.email-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 10px 0;
}

.email-salutation {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin: 25px 0 10px;
  display: flex;
}

.email-teaser-text {
  line-height: 23px;
}

.email-recommendation {
  margin: 40px 0;
}

.email-recommendation.university {
}

.email-recommendation.syngenta {
}

.recommendation-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 5px 0;
}

.para-heading {
  font-weight: 600;
}

/* Tables */

.table-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  margin: 15px 0;
}

.para-text {
  line-height: 23px;
  margin-bottom: 15px;
}

.tables {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.scoutedFieldsTable {
  border-collapse: collapse;
  border: none;
  font-size: 14px;
  text-align: center;
}

.weatherStationHeader {
  border-radius: 10px 10px 0px 0px;
}

.table-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: #994500;
  color: #f3f4f6;
  padding: 12px 0 7px;
  text-align: center;
}

.weatherStationName {
  border-radius: 10px 10px 0px 0px;
}

.column {
  background: rgba(230, 161, 69, 0.5);
  color: #14151c;
  padding: 20px 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  border-right-color: #14151c;
  border-right-style: solid;
  border-right-width: 1px;
  border-left-color: #14151c;
  border-left-style: solid;
  border-left-width: 1px;
}

.tableHeader2 {
  background-color: #616161;
  font-size: 14px;
  color: white;
  font-weight: 400;
  border-color: white;
}

.weatherStationMonitoringText {
  background-color: #994500;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border: 1px solid #994500;
}

.data-column {
  padding: 10px 5px;
  text-align: center;
  border: 1px solid #14151c;
  font-size: 14px;
}

.tableHeader3 {
  font-weight: 400;
  padding: 10px 4px;
  color: white;
  border-color: white;
}

tr,
td {
  padding: 10px 5px;
}

/* legend */
.legends-container {
  margin: 30px 0;
}

.main-legend {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 5px;
}

.legends {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 24px;
  gap: 24px;
}

.legend {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  gap: 10px;
}

.tendency {
  margin-top: 16px;
}

.legend-image {
  height: 30px;
  width: 30px;
}

.legend-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.legend-row.five-legends {
  grid-template-columns: repeat(5, 1fr);
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-item .item-label {
  margin-left: 10px;
}

.rotate90 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.rotate180 {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.rotate45 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.rotate135 {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

.item-box {
  height: 20px;
  width: 20px;
  border: 1px solid #a3a9b9;
  border-radius: 50%;
}

.item-box.green {
  background-color: #197e09;
  border-color: #197e09;
}

.item-box.parrot-green {
  background-color: #23f900;
  border-color: #23f900;
}

.item-box.yellow {
  background-color: #faff00;
  border-color: #faff00;
}

.item-box.orange {
  background-color: #ff6b00;
  border-color: #ff6b00;
}

.item-box.red {
  background-color: #ff0000;
  border-color: #ff0000;
}

/* profile */
.profile-container {
  text-align: center;
  margin: 48px 0;
}

.link-div {
  padding: 20px 40px;
}

.click-here-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  background-color: #994500;
  color: #fff;
  padding: 10px 32px;
  border-radius: 24px;
  text-align: center;
  text-decoration: none;
}

.contact-person {
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  margin: 20px 0;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 30px 10px;
}

.profile-img {
  width: 120px;
  min-width: 120px;
  height: 180px;
  min-height: 180px;
  object-fit: fill;
  box-shadow: 1px 1px 2px 2px #888888;
}

.profile-text {
  text-align: start;
}

.profile-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  margin: 20px 0;
}

/* feedback */

.feedback-text {
  font-size: 12px;
  line-height: 23px;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .email-template {
    margin-left: 0;
    margin-right: 0;
  }

  .table-1 table,
  .table-2 table {
    display: block;
    overflow-x: auto;
  }
  .arrow {
    /* transform: rotate(-90deg);
          margin-left:auto;
          /* margin-top:8px; */
    /* float:right; */
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 3px;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    float: right;
    margin-right: 10px;
    box-shadow: 1px 1px 1px;
  }

  .legend-row {
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .legend-row.five-legends {
    grid-template-columns: auto auto;
  }

  .link-div {
    padding-left: 0;
    padding-right: 0;
  }

  .profile-container {
    margin: 36px 0;
  }
}

@media screen and (min-width: 480px) and (max-width: 767.7px) {
  .table-1 table,
  .table-2 table {
    display: block;
    overflow-x: auto;
  }
  .arrow {
    border: solid black;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 3px;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    float: right;
    margin-right: 10px;
    box-shadow: 1px 1px 1px;
  }

  .legend-row {
    grid-template-columns: auto auto auto;
    gap: 10px;
  }

  .legend-row.five-legends {
    grid-template-columns: auto auto auto;
  }

  .link-div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@root-entry-name: default;