.reset-password-container {
  text-align: start;
  width: 100%;
}

.reset-password-button-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 100px;
}

.reset-password-button {
  height: 40px;
  color: #fff;
  background: #994500;
  border: none;
  border-radius: 24px;
  padding: 0 30px;
}

.provide-reset-text {
  color: #4d5165;
  margin: 24px 0;
}

// success
.reset-password-container-success {
  margin: 10px 0 30px;
}

.reset-password-success-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 36px;
}

.provide-reset-success-text {
  margin-top: 36px;
}

@root-entry-name: default;