.spray-calculator-content-div {
  padding: 12px;
  margin-bottom: 15px;
}

.dropdownheading {
  margin-top: 12px;
  font-size: 14px;
  color: #2f3031;
}

.dropdown-row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.content-text {
  margin: 12px;
  color: #2f3031;
  border: 1px solid #e6a145;
  border-radius: 4px;
  background-color: #e6a145;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 6px 10px;
}

@media screen and (max-width: 479.5px) {
  .content-text {
  }
}

@media screen and (min-width: 480px) and (max-width: 649.5px) {
}

@media screen and (min-width: 650px) and (max-width: 767.5px) {
}

@root-entry-name: default;